import React, { useState } from 'react'
import style from './Row.module.scss'
import FavoriteStar from './FavoriteStar'
import crown from './images/crown.svg'

const Row = ({ id, babyName, userName, rankNumber, votesCount, babyImage, favorite, favoritesMode, subscriptionPro }) => {
  const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' })
  const enCardinalRules = new Intl.PluralRules("en-US")
  const [ isFavorite, setFavorite ] = useState(favorite)

  const ordinalSuffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const votesSuffixes = new Map([
    ['one', 'vote'],
    ['other', 'votes'],
  ])

  const formatOrdinals = (n) => {
    const rule = enOrdinalRules.select(n)
    const suffix = ordinalSuffixes.get(rule)
    return `${n}${suffix}`
  }

  const formatVotes = (n) => {
    const rule = enCardinalRules.select(n)
    const vote = votesSuffixes.get(rule)
    return `${n} ${vote}`
  }

  if (favoritesMode && !isFavorite) return null

  return (
    <div className={style.row}>
      <a className={style.wrapper} href={`/participants/${id}`}>
        <img className={style.image} alt={ babyName } src={ babyImage } />
        <div className={style.text}>
          <div className={style.babyName}>
            { babyName }
            {/*{ subscriptionPro*/}
            {/*  &&*/}
            {/*  <a href={'/pro'} className={style.badgeSmall}>*/}
            {/*    <img  className={style.crown} src={crown}/>*/}
            {/*    Featured*/}
            {/*  </a>*/}
            {/*}*/}

          </div>
          <div className={style.extraInfo}>
            <div className={style.infoText}>{ formatOrdinals(rankNumber) }</div>
            <div className={style.ellipse} />
            <div className={style.infoText}>{ formatVotes(votesCount) }</div>
            <div className={style.ellipse} />
            <div className={style.infoText}>{ userName }</div>
          </div>
        </div>
      </a>
      <FavoriteStar isFavorite={isFavorite} setFavorite={setFavorite} babyName={babyName} id={id} />
    </div>
  )
}

export default Row
