import React, {useEffect, useState} from 'react'
import axios from "axios";
import PayPal from "../../PayPal";
import StripeSavedCards from "../../Stripe/SavedCards";
import StripeCheckout from "../../Stripe";
import style from '../BuyButton.module.scss'
import ContestEndingTimer from './timer'

const fetcher = (url, params) => axios.get(url, {params}).then(res => res.data)

export default function BuyButtonContent({product, participantId, contest_end_at, order_end_time, is_second_purchase}) {
  const createOrdersPath = `/api/products/${product.id}/create_orders.json`
  const [data, setData] = useState(null)
  const [showStripeCheckout, setShowStripeCheckout] = useState(true);
  const [animationClass, setAnimationClass] = useState('fadeIn');

  useEffect(() => {
    axios.post(createOrdersPath, { participant_id: participantId, is_second_purchase: is_second_purchase }).then(({data}) => setData(data))
  }, [])

  const handleCardSelection = (selectedCardId) => {
    if (selectedCardId) {
      setAnimationClass('fadeOut');
      setTimeout(() => {
        setShowStripeCheckout(false);
      }, 500);
    } else {
      setShowStripeCheckout(true);
      setAnimationClass('fadeIn');
    }
  };

  if (!data) return <p>Loading...</p>

  return (
    <>
        {
            data.baby && (
                <div
                    className={style.avatar}
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundImage: `url(${data.baby.avatar})`,
                        backgroundSize: 'cover',
                        margin: '10px auto'
                    }}
                ></div>
            )
        }
      <h5 className={style.productTitle}>{product.title}</h5>

      <ContestEndingTimer
        contestEndTime={contest_end_at}
        orderEndTime={order_end_time}
      />

      {data.baby && <p className={style.babyName}>{data.baby.name}</p>}
      <PayPal orderId={data.paypal.id} participantId={data.paypal.participant_id} fetcher={fetcher} cents={data.paypal.price_cents} currency={data.paypal.price_currency} />

      <div className={style.divider}>
        <span className={style.dividerText}>
          <p className={style.textBody2}>or pay with card</p>
        </span>
      </div>

      <>
        <StripeSavedCards
          stripePaymentMethods={data.payment_methods}
          orderId={data.stripe.id}
          participantId={data.stripe.participant_id}
          cents={data.stripe.price_cents}
          currency={data.stripe.price_currency}
          handleCardSelection={handleCardSelection}
          showStripeCheckout={showStripeCheckout}
          setShowStripeCheckout={setShowStripeCheckout}
        />
        {showStripeCheckout && (
          <div className={`${style.stripeCheckoutContainer} ${style[animationClass]}`}>
            <StripeCheckout
              stripePaymentMethods={data.payment_methods}
              orderId={data.stripe.id}
              cents={data.stripe.price_cents}
              currency={data.stripe.price_currency}
              participantId={data.stripe.participant_id}
              fetcher={fetcher}
            />
          </div>
        )}
      </>
    </>
  )
}